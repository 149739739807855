<template>
  <div class="Checkout__container relative mt-8">
    <div slim data-cy-region="checkout-payment">
      <form @submit="onSubmit">
        <!-- <Back class="px-5 lg:px-0" :to="isLoggedIn ? '/checkout/shipping' : '/checkout/guest-shipping'">
          <span class="text-primary-1-100 font-medium uppercase">
            {{ $t('backToShipping') }}
          </span>
        </Back> -->
        <div class="flex items-center justify-between [ px-6 lg:px-0 ]">
          <h3 class="flex items-center">
            <SvgIconDeliveryInfo width="24" height="24" class="mr-3" />
            <span class="font-bold text-lg-1 lg:text-1.5xl"> {{ $t('shippingInformation') }}</span>
          </h3>

          <AppLink
            :to="`/checkout/${mode === 'guest' ? 'guest-shipping' : 'shipping'}`"
            class="[ flex items-center ] [ uppercase text-sm font-semibold ]"
          >
            <svg-icon-edit class="w-6 h-6 mr-3" />
            <span class=""> {{ $t('edit') }} </span>
          </AppLink>
        </div>

        <dl class="[ grid grid-cols-1 ] [ font-bold text-sm ] [ mt-4 px-6 lg:px-0 ]">
          <dt class="mt-7 text-gray-900 font-normal">{{ $t('shippingMethod') }}</dt>
          <dd v-if="isStorePickup" class="mt-2 text-primary-1-100">
            {{ $t('storePickup') }}
          </dd>
          <dd v-else class="mt-2">{{ $t('homeDelivery') }}</dd>

          <template v-if="address">
            <dt class="text-gray-900 mt-7 font-normal">{{ $t('shippingAddress') }}</dt>
            <dd class="mt-2 flex flex-col">
              <span>{{ address.firstname }} {{ address.lastname }}</span>
              <span>{{ address.telephone }}</span>
              <span v-if="address && address.street">{{ address.street[0] }}</span>
            </dd>
          </template>

          <!-- <template v-if="selectedShippingMethod">
          <dt class="text-primary-1-100 font-medium mt-7">{{ $t('deliveryType') }}</dt>
          <dd class="mt-2 text-gray-500">
            <div>
              {{ selectedShippingMethod.method_title }} , ( <Money :value="selectedShippingMethod.amount.value" /> )

              <span v-if="selectedShippingMethod.max_days && selectedShippingMethod.min_days">
                ,{{
                  $t('shippingMethods.deliverWithin', {
                    minDays: selectedShippingMethod.min_days,
                    maxDays: selectedShippingMethod.max_days,
                  })
                }}
              </span>
            </div>
          </dd>
        </template> -->
        </dl>

        <div class="flex bg-primary-1-05 w-full h-1 mt-8 mb-10" />

        <h3 class="[ px-6 lg:px-0 ] [ text-md lg:text-lg font-bold ] [ flex items-center ]">
          <SvgIconWallet class="mr-4" width="24" height="22" />
          {{ $t('payment') }}
        </h3>

        <fieldset class="flex flex-col px-6 lg:px-0">
          <CheckoutCoupons class="mt-6" @is-open="e => (isCouponsOpen = e)" />
        </fieldset>

        <fieldset class="flex flex-col px-6 mt-6 lg:px-0">
          <legend></legend>
          <CheckoutGiftCard @is-open="e => (isGiftCardOpen = e)" @change-payment-methods="e => (paymentMethods = e)" />
        </fieldset>

        <fieldset v-if="!codeFree" class="px-5 lg:px-0 mt-8 flex flex-col">
          <legend class="font-bold text-md lg:text-lg-1">{{ codeFree ? '' : $t('selectPaymentMethod') }}</legend>

          <div class="mt-7 relative">
            <div
              v-if="isUpdatingPaymentState"
              class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
            >
              <Spinner class="w-8 h-8" />
            </div>

            <PaymentStrategyDirector
              :class="{ 'opacity-50 pointer-events-none': loading }"
              :payment-methods="paymentMethods"
              :code-free="codeFree"
            />
          </div>
        </fieldset>
        <!-- <CheckboxInput v-model="agreedToTerms" rules="required" class="px-5 md:px-0 mt-16">
        <template #default>
          <p class="text-sm text-black">
            By continuing you agree to our
            <a href="#" class="text-black underline">Terms and Conditions</a> and
            <a href="#" class="text-black underline">Privacy Policy</a>
          </p>
        </template>
      </CheckboxInput> -->

        <div class="hidden md:flex">
          <AppButton
            type="submit"
            class="mt-10 w-full md:w-80 justify-center uppercase"
            :disabled="!meta.valid || isCouponsOpen || isGiftCardOpen"
            :loading="isPlacingOrder || isSubmitting || loading"
            inverted
          >
            <span class="text-center uppercase"> {{ $t('placeOrder') }}</span>
          </AppButton>
        </div>
      </form>
    </div>

    <div class="mt-16 md:mt-0 flex flex-col self-start md:sticky md:top-0 px-5 md:px-0">
      <div class="flex flex-col items-center justify-center">
        <CheckoutPaymentSummary class="w-full">
          <template #cta-placeholder>
            <div class="md:hidden w-full mt-8">
              <AppButton
                type="button"
                class="flex md:hidden w-full justify-center uppercase"
                :disabled="!meta.valid || isCouponsOpen || isGiftCardOpen"
                :loading="isPlacingOrder || isSubmitting || loading"
                inverted
                @click="onSubmit"
              >
                <span class="text-center uppercase"> {{ $t('placeOrder') }}</span>
              </AppButton>
            </div>
          </template>
        </CheckoutPaymentSummary>
      </div>

      <CheckoutCartSummary />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ShippingMethod } from '@robustastudio/e-commerce/dist/runtime/composables/cart/place-order';
import { AvailablePaymentMethod } from '@robustastudio/e-commerce/dist/runtime/graphql/Cart';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { address, isStorePickup, itemCount } = useCartAttributes();
const isCouponsOpen = ref(false);
const isGiftCardOpen = ref(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { redirect } = useAppRouter();

definePageMeta({ layout: 'checkout' });

const { t: $t } = useI18n({ useScope: 'local' });
const { t } = useI18n({
  useScope: 'global',
});

/**
 *
 */
// watch(
//   isPageLoading,
//   newState => {
//     if (!newState && !itemCount.value) {
//       redirect('/');
//     }
//   },
//   {
//     immediate: true,
//   }
// );

const { validationSchema } = useCheckoutPaymentValidationSchema();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { handleSubmit, values, errors, isSubmitting, meta } = useForm({
  validationSchema,
  initialValues: {
    shippingMethod: null,
    paymentMethod: null,
  },
});

useSetAutoShippingMethod();
useSetAutoPaymentMethod();
const route = useRoute();
const mode = computed<'customer' | 'guest'>(() => {
  return (route.query.mode as 'customer' | 'guest') || 'customer';
});

const onSubmit = handleSubmit(async ({ paymentMethod, shippingMethod }) => {
  try {
    console.log(paymentMethod, shippingMethod);
    await submitCheckout(
      ref(paymentMethod) as unknown as Ref<AvailablePaymentMethod>,
      ref(shippingMethod) as unknown as Ref<ShippingMethod>
    );
  } catch (e) {
    console.log(e);
  }
});

const {
  loading,
  submitCheckout,
  isPlacingOrder,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentMethods: cartPaymentMethods,
  shippingMethods,
  isInvalidOptions,
  isUpdatingShippingMethod,
  isUpdatingPaymentState,
  resetErrors,
} = useCheckout();

watch(values, newValues => {
  if (isInvalidOptions.value && Object.keys(newValues).length && Object.keys(newValues)?.every(value => !!value)) {
    resetErrors();
  }
});

useAppSeoMeta({
  title: 'Payment Information',
  description: 'Checkout by filling your payment information and paying for your order',
});

const paymentMethods = ref(cartPaymentMethods.value);

onMounted(() => {
  /**
   * update payment method when fetched and show them
   * check if it contains code free, set payment method to free
   * other wise set to cash on delivery
   */
  const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');

  const unWatch = watch(cartPaymentMethods, newValue => {
    paymentMethods.value = newValue;
    unWatch();
  });
  paymentMethods.value = cartPaymentMethods.value;
  watch(paymentMethods, newValue => {
    const free = newValue.find(e => e?.code === 'free');
    if (free) {
      selectedPaymentMethod.value = free as AvailablePaymentMethod;
    } else {
      selectedPaymentMethod.value = newValue.find(e => e?.code === 'cashondelivery') as AvailablePaymentMethod;
    }
  });
});

const codeFree = computed(() => paymentMethods.value?.some(e => e?.code === 'free'));

/**
 * Set shipping method on load
 *
 */
onMounted(() => {
  const { setValue: setShippingMethod } = useField<ShippingMethod>('shippingMethod');
  setShippingMethod(shippingMethods.value[0]);
});
</script>

<i18n>
{
  "en":{
    "placeOrder": "Place Order",
    "backToShipping": "Back To Shipping",
    "storePickup": "Store Pickup",
    "shippingInformation": "Shipping Information",
    "homeDelivery": "Home Delivery",
    "edit": "EDIT",
    "shippingMethod": "Shipping Method",
    "shippingAddress": "Shipping Address",
    "deliveryType": "Delivery Type",
    "selectDeliveryType": "Select Delivery Type",
    "selectPaymentMethod": "Select Payment Method",
    "phone": "Phone",
    "name": "Name",
    "address": "Address",
    "payment": "Payment Information"
  },
  "ar": {
    "placeOrder": "بدء الطلب",
    "backToShipping": "العوده للتوصيل",
    "storePickup": "الاستلام من الفرع",
    "shippingInformation": "معلومات التوصيل",
    "homeDelivery": "التوصيل المنزلى",
    "edit": "تعديل",
    "shippingMethod": "طريقه التوصيل",
    "shippingAddress": "عنوان التوصيل",
    "deliveryType": "نوع التوصيل",
    "selectDeliveryType": "اختار طريقه التوصيل",
    "selectPaymentMethod": "اختار طريقه الدفع",
    "phone": "التلفون",
    "name": "الاسم",
    "address": "العنوان",
    "payment": "معلومات الدفع"
  }
}
</i18n>
