<template>
  <div>
    <form @submit="onSubmit">
      <template v-if="!appliedCoupon.length">
        <button
          type="button"
          class="flex items-center justify-between w-full [ text-primary-1-100 ]"
          @click="toggleCoupons = !toggleCoupons"
        >
          <svg-icon-coupon width="25" height="17" class="mr-2 text-primary-1-100" />
          <span class="[ capitalize font-semibold text-sm ]">
            {{ $t('haveAPromoCode') }}
          </span>

          <div
            class="checkbox ml-auto flex justify-center items-center"
            :class="{
              active: toggleCoupons || appliedCoupon.length,
            }"
          >
            <svg-icon-check class="w-3 h-3" :class="{ hidden: !toggleCoupons }" />
          </div>
        </button>
      </template>
      <Coupons
        v-if="toggleCoupons || appliedCoupon.length"
        :applied-coupons="appliedCoupon"
        :loading="loading"
        class="block"
        @remove="() => removeCouponOnCart()"
      >
        <template #cancel>
          <button
            type="button"
            class="text-system-red [ capitalize underline font-semibold text-sm ] ml-5"
            @click="toggleCoupons = !toggleCoupons"
          >
            {{ $t('cancel') }}
          </button>
        </template>
      </Coupons>
    </form>
  </div>
</template>
<script setup lang="ts">
import { object, string } from 'yup';

defineComponent({
  name: 'CheckoutCoupons',
});

const { appliedCoupon: cartCoupons } = useCartAttributes();
const { isFetching: isApplyingPromoCode, applyCoupon } = useApplyPromocode();
const { isFetching: isRemovingPromoCode, removeCode } = useRemovePromocode();

const { error, success } = useAlerts();
const { t } = useI18n();
const { t: $t } = useI18n({
  useScope: 'local',
});

const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string().required(),
    })
  ),
});

const toggleCoupons = ref(false);

const emit = defineEmits<{
  (event: 'isOpen', isOpen: boolean): void;
}>();
const onSubmit = handleSubmit(async ({ code }) => {
  try {
    await applyCoupon(code);
    success(t('cartSuccess').toString(), t('couponAdded').toString());
    toggleCoupons.value = false;
    resetForm();
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());

    setErrors({ code: t((e as Error).message).toString() });
  }
});

async function removeCouponOnCart() {
  try {
    await removeCode();
    success(t('cartSuccess').toString(), t('couponRemoved').toString());
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
  }
}

watch(
  toggleCoupons,
  newValue => {
    emit('isOpen', newValue);
  },
  {
    immediate: true,
  }
);

const appliedCoupon = computed(() => (cartCoupons.value ? [cartCoupons.value] : []));

const loading = computed(() => isApplyingPromoCode.value || isRemovingPromoCode.value);
</script>
<i18n>
{
  "en": {
    "haveAPromoCode": "Do You Have A Promo code?",
    "cancel": "Cancel"
  },
  "ar": {
    "haveAPromoCode": "لديك برومو كود ؟",
    "cancel": "الغاء"
  }
}
</i18n>

<style lang="postcss" scoped>
.checkbox {
  @apply h-6 w-6  border border-primary-1-100;

  &.active {
    @apply border-white;
    outline: 1px solid black;
  }
}
</style>
